// Imported from my personal website.
// https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965
// https://github.com/GedalyaKrycer/genhybridsystems/blob/main/src/components/ui/InputField/InputField.js

import React, {useState} from 'react';
//import './mcFormStyles.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {types} from "sass";
import Error = types.Error;

//Checks if all the fields are filled and if an @ sign is used in the email field
const validateInput = (values: any) => {
    return (values.some((f: any) => f === "")
        || values[0].indexOf("@") === -1);
}


const BiggerText = (props: any) => {
    return <span style={{fontSize: "133%"}} className={props.className}>{props.children}</span>
}


interface CustomFormProps {
    status: string | any;
    message: string | Error | null;
    onValidated: (data: any) => void;  // FormsData expected.
}

const CustomForm = ({status, message, onValidated }: CustomFormProps) => {

    const [email, setEmail] = useState('');

    const handleSubmit = (e: any) => {
        // TODO OPTIONAL: Regex FTW!
        e.preventDefault();
        email &&
        // firstName &&
        // lastName &&
        email.indexOf("@") > -1 &&
        email.indexOf(".") > email.indexOf("@") &&
        onValidated({
            // EMAIL: email,
            // MERGE1: firstName,
            // MERGE2: lastName,
            MERGE0: email,
        });
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}
            className="mailchimpForm">
            <h3 style={{fontSize: "2vw",}}>
                <img src="/UI%20Graphics/game-icons.net/envelope.png"
                     alt="Envelope Icon"
                     style={{height: "40px", paddingRight: "12px",}}/>
                <span>{status === "success" ? "Success!" : "Sign up to mailing list?"}</span>
                <img src="/UI%20Graphics/game-icons.net/envelope.png"
                     alt="Envelope Icon"
                     style={{height: "40px", paddingLeft: "12px",}}/>
            </h3>
            <hr style={{border: "none", height: "4px", color: "#fff", backgroundColor: "#fff",  /*Both colors for old and modern browsers*/}}/>
            {/*Mobile: 4.5vw*/}
            {/*PC: 1.2vw*/}
            {/*Old: 16px*/}
            <p style={{margin: "auto"}}>Would you like to hear more from me, in mail form?<br/>
                I'm meaning to send only updates about <BiggerText className="wavy">large</BiggerText> stuff happening in this mailing list.<br/>
                Like major updates to <BiggerText className="wavy">games</BiggerText> I make, mostly.<br/>
                <span style={{color: "#999"}}>
                    <img src="/UI%20Graphics/game-icons.net/hazard-sign.png"
                         alt="Warning Icon"
                         style={{height: "24px", paddingRight: "12px",
                         filter: "brightness(60%)"}}/>
                    (The mail might appear in your "Promotions" mail tab.)</span></p>
            <div style={{height: "2vh"}}/>

            {status === "sending" && (
                <div className="mc__alert mc__alert--sending">
                    Sending...
                </div>
            )}
            {status === "error" && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{__html: message == null ? "null" : message.toString()}}
                />
            )}
            {status === "success" && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{__html: message == null ? "null" : message.toString()}}
                />
            )}
            {status !== "success" ? (
                <>
                    {/*<InputField
                    label="First Name"
                    onChangeHandler={setFirstName}
                    type="text"
                    value={firstName}
                    placeholder="Jane"
                    isRequired
                />*/}
                    {/*<label>
                    "First Name"
                    <input
                        onChange={(e) => setFirstName(e.target.value)}
                        type="text"
                        placeholder="Jane"
                        value={firstName}
                        required
                    />
                </label>*/}

                    {/*<InputField
                    label="Last Name"
                    onChangeHandler={setLastName}
                    type="text"
                    value={lastName}
                    placeholder="Doe"
                    isRequired
                />*/}
                    {/*<label>
                    "Last Name"
                    <input
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        placeholder="Doe"
                        value={lastName}
                        required
                    />
                </label>*/}

                    {/*<InputField
                    label="Email"
                    onChangeHandler={setEmail}
                    type="email"
                    value={email}
                    placeholder="your@email.com"
                    isRequired
                />*/}
                    <label style={{fontSize: "1.5em"}}>
                        Email<i> </i>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            placeholder="your@email.com"
                            value={email}
                            required
                            style={{fontSize: "1em", maxWidth: "75vw"}}
                        />
                    </label>
                    <div style={{height: "2vh"}}/>
                </>
            ) : null}

            {/*<InputField
                label="subscribe"
                type="submit"
                formValues={[email, firstName, lastName]}
            />*/}
            {(status !== "success" && <input
                style={{fontSize: "1.5em"}}
                className='primaryBtn primaryBtn--big g__justify-self-center'
                type='submit'
                value="Sign up to mailing list"
                disabled={validateInput([email])}
            />)}
        </form>
    );
};

const MailchimpFormContainer = () => {
    const MAILCHIMP_U = "01c644e28c0179ab1342ae866";
    const MAILCHIMP_ID = "47ad4b8f86";

    const postUrl = `https://blawnode.us18.list-manage.com/subscribe/post?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`;
    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => {
                            subscribe(formData);
                        }}
                    />
                )}
            />
        </div>
    );
};

export default MailchimpFormContainer;