import {NewsPostData, NewsPostHeaderData} from "../Data Types/NewsPostData";
import { combineReducers } from 'redux';

// May be a good idea to fetch all of the news straight up.
// Perhaps fetch by groups of 10, great when there's shitloads of news. Consider Nitrome.
// Perhaps do as originally suggested by ChatGPT, fetch all headers, and have only one selectedPost fetched when requested.
export interface NewsStateType {
    headers: NewsPostHeaderData[];
    selectedPostId: string | null;
    selectedPost: NewsPostData | null;
}

const initialNewsState: NewsStateType = {
    headers: [],
    selectedPostId: null,
    selectedPost: null,
};

const SET_HEADERS = 'SET_HEADERS';
const SET_SELECTED_POST_ID = 'SET_SELECTED_POST_ID';
const SET_SELECTED_POST = 'SET_SELECTED_POST';

type Action =
    | { type: 'SET_HEADERS'; payload: NewsPostHeaderData[] }
    | { type: 'SET_SELECTED_POST_ID'; payload: string | null }
    | { type: 'SET_SELECTED_POST'; payload: NewsPostData | null };

const newsReducer = (state = initialNewsState, action: Action): NewsStateType => {
    switch (action.type) {
        case SET_HEADERS:
            return { ...state, headers: action.payload };
        case SET_SELECTED_POST_ID:
            return { ...state, selectedPostId: action.payload };
        case SET_SELECTED_POST:
            return { ...state, selectedPost: action.payload };
        default:
            return state;
    }
};

const rootReducer = combineReducers({news: newsReducer});
//export default rootReducer;  // TODO: Use this
export default newsReducer;

export type RootState = NewsStateType;
//export type RootState = ReturnType<typeof newsReducer>;  // I used to comment this out. Why?
