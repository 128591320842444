import { Dispatch } from 'redux';
import {NewsPostData, NewsPostHeaderData} from "../Data Types/NewsPostData";
import {fetchNewsPosts} from "../Services/NewsPostDataService";
import {RootState} from "./reducers";

export const setHeadersActionName = "SET_HEADERS";
export const setSelectedPostIdActionName = "SET_SELECTED_POST_ID";
export const setSelectedPostActionName = "SET_SELECTED_POST";
//export const fetchHeadersActionName = "IDK1";
//export const fetchPostActionName = "IDK2";

export interface SetHeadersAction {
    type: string;
    payload: NewsPostHeaderData[];
}

export interface SetSelectedPostIdAction {
    type: string;
    payload: string | null;
}

export interface SetSelectedPostAction {
    type: string;
    payload: NewsPostData | null;
}

export interface FetchPostAction {
    type: string;
}

export const setHeaders = (headers: NewsPostHeaderData[]) => ({
    type: setHeadersActionName,
    payload: headers,
});

export const setSelectedPostId = (postId: string | null) => ({
    type: setSelectedPostIdActionName,
    payload: postId,
});

export const setSelectedPost = (post: NewsPostData | null) => ({
    type: setSelectedPostActionName,
    payload: post,
});

// Async actions

//  After the return statement, set the state to "LOADING".
//  Perhaps show a loading icon or something. Same forall async actions.
export const fetchHeaders = () => {
    return async (dispatch: Dispatch<ActionTypes>) => {
        const response = await fetch('/api/news-headers');
        const data: NewsPostHeaderData[] = await response.json();
        dispatch(setHeaders(data));
    };
};

export const fetchPost = (postId: string, useApi: boolean) => {
    return async (dispatch: Dispatch<ActionTypes>, getState: () => RootState) => {
        const newsPostDatas: NewsPostData[] = await fetchNewsPosts(useApi);
        //const response = await fetch(`/api/news-posts/${postId}`);
        //const data: NewsPostData = await response.json();
        let data: NewsPostData | undefined = undefined;
        data = newsPostDatas.find((x: NewsPostData) => x.id === postId);
        if(data) {
            console.log(`FETCHED POST '${postId}': ${JSON.stringify(data)}`);
        }
        else {
            console.log(`COULDN'T FETCH THE POST '${postId}'`);
        }
        dispatch(setSelectedPost(data ? data : null));
    };
};

export type ActionTypes =
    | SetHeadersAction
    | SetSelectedPostIdAction
    | SetSelectedPostAction
//    | FetchHeadersAction
    | FetchPostAction
;//