import React from "react";
import {NewsPostData} from "../Data Types/NewsPostData";
import {stringifyDate} from "../Helper Scripts/DateHelper";
import parse from "html-react-parser"
import {setSelectedPost, setSelectedPostId} from "../Redux Store/actions";
import {AppDispatch} from "../Redux Store/store";
import {useDispatch} from "react-redux";

type newsPostDisplayProps = {
    postData: NewsPostData
}

const NewsPostDisplay = ({postData,}: newsPostDisplayProps): React.JSX.Element => {

    const dispatch: AppDispatch = useDispatch();

    function CloseNewsPost()
    {
        dispatch(setSelectedPostId(null));
        dispatch(setSelectedPost(null));  // Will cause the news list panel to update.
        console.log("TODO: Close this news post!!!!!!!!!");
    }

    return <div className="news-post-display"
        style={{width: "100%", height: "100%",
        display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <div style={{width: "100%", height: "80%",
            display: "flex", flexDirection: "column",
            overflow: "overlay"}}>
            <h4>{postData.title}</h4>  {/*Responsify! h1 works on PC, h4 works on laptop*/}
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span>{postData.author}</span>
                <span style={{marginLeft: "32px"}}>{stringifyDate(postData.releaseDate)}</span>
            </div>

            <hr style={{width: "100%" /*flexbox breaks <hr/>*/}}/>

            {postData.descriptionHtmlText ? parse(postData.descriptionHtmlText) : ""}
        </div>

        <div style={{height: "15%",
            display: "flex", justifyContent: "center", alignItems: "center",}}>
            <div onClick={CloseNewsPost} className="btn btn-dark"
                style={{marginLeft: "auto", marginRight: "auto", backgroundColor: "#76428a",}}>Close me</div>
        </div>
    </div>
}

export default NewsPostDisplay;