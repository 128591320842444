import IsMobile from "../IsMobile";
import NewsPanel from "../SegmentSubComponents/NewsPanel"
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../Redux Store/reducers";
import NewsPostDisplay from "../SegmentSubComponents/NewsPostDisplay";


const HomeHtmlText = (): React.JSX.Element => {
    return <div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center",
            width: "100%",}}>
            <img src="/Blawnode_Logo_MAX.png" className="App-logo" alt="logo"
                 style={{height: "8vmin",}}/>
            {/*<div style={{width: "32px",}}/>
                <a className="App-link" style={{fontSize: "calc(8px + 1.5vmin)",
                    marginLeft: "auto",}}>
                    Exit news segment</a>*/}
        </div>

        <hr style={{width: "100%" /*flexbox breaks <hr/>*/}}/>
        <br/>

        <p>
            This website is a work in progress.
        </p>

        <br/>

        <a
            className="App-link"
            href="https://blawnode.itch.io/"
            target="_blank"
            rel="noopener noreferrer"
        >
            🎮 blawnode.itch.io (Games!)
        </a>

        <br/>

        <a
            className="App-link"
            href="https://www.youtube.com/@blawnode"
            target="_blank"
            rel="noopener noreferrer">
            ▶ My YouTube Channel (Music! ♪)
        </a>

        <br/>

        <p style={{fontSize: "80%"}}>
            Feel free to contact me either on Discord (@blawnode), Email (blawnode@gmail.com).
        </p>
    </div>
}


const WipSegment_NonMobile = () => {
    const selectedPost = useSelector((state: RootState) => state.selectedPost); // Accessing the data from Redux store

    const contentPanelStyle = {
        width: "60%", height: "70%",
        display: "flex", flexDirection: "column", alignItems: "flex-start",
        ...((selectedPost == null) ? {} : {textAlign: "left", overflow: "scroll",})
    };
    const contentPanelStyle2 = {
        ...{width: "60%", height: "70%",
        display: "flex", flexDirection: "column", alignItems: "flex-start",},
        ...((selectedPost == null) ? {} : {})
        //textAlign: "left",
        //overflow: "scroll",
    };

    return <div id="wip"
                style={{
                    width: "80vw", height: "100vh",
                    display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",
                }}>
        <div style={{
            width: "32.5%", height: "inherit",
            display: "flex", alignItems: "center",
        }}>
            <NewsPanel/>
        </div>

        <div style={{
            width: "57.5%",
            display: "flex", flexDirection: "column", alignItems: "flex-start",
            textAlign: "left",
            ...((selectedPost == null) ? {} : {height: "70%", overflow: "auto", overflowWrap: "anywhere",})
        }}>
            {/*{selectedPost == null ? <HomeHtmlText/> : JSON.stringify(selectedPost)}*/}
            {selectedPost == null ? <HomeHtmlText/> : <NewsPostDisplay postData={selectedPost}/>}
        </div>

        {/*<div id="blanks" style={{
            width: "10%",
        }}>
        </div>*/}
    </div>;
}

const WipSegment_Mobile = () => {
    return <div id="wip"
                style={{
                    width: "80vw", maxHeight: "120vh",
                    marginTop: "5vh", gap: "5vh",
                    display: "flex", flexDirection: "column", alignItems: "center",
                }}>
        <div style={{
            width: "100%", height: "20vh",
            display: "flex", flexDirection: "column", alignItems: "center",
        }}>
            <NewsPanel/>
        </div>

        <div style={{
            maxHeight: "95vh",
            display: "flex", flexDirection: "column", alignItems: "flex-start",
            textAlign: "left",
        }}>
            <HomeHtmlText/>
        </div>

        {/*<div id="blanks" style={{
            width: "10%",
        }}>
        </div>*/}
    </div>;
}

const WipSegment = () => {
    return IsMobile() ? <WipSegment_Mobile/> : <WipSegment_NonMobile/>;
};

export default WipSegment;