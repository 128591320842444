import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import {Nav} from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from "react-router-dom";

function MainNavbar() {
    return (
        // <Container style={{width: "100vw", height: 0, position: "absolute"}}>
        <Container>
            <Navbar
                fixed="top"
                    // expand="lg"
                // collapseOnSelect
                className="rounded-pill"
                style={{
                    width: "60%",
                    height: "5vh",
                    top: "16px",
                    margin: "auto",
                    backgroundColor: "#76428a",
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",}}
                >
                <Container style={{width: "90%", height: "inherit",
                    display: "flex", justifyContent: "space-around",}}>
                    {/*href="#start" makes focus happen twice, href="#" makes focus happen once.*/}
                    <Navbar.Brand href="/#start" className="mainNavbarElement"
                                  // tabIndex={1}
                        style={{height: "inherit",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",}}>
                        <img src="/Blawnode_Logo_MAX.png" style={{height: "80%",}}/><i style={{width: "8px"}}></i>Blawnode
                    </Navbar.Brand>
                    {/*<Nav.Link href="/#wip" className="mainNavbarElement"
                              // tabIndex={2}
                              style={{height: "inherit",
                        display: "flex", alignItems: "center",}}>
                        <img src="/UI%20Graphics/game-icons.net/newspaper.png"
                             alt="Newspaper Icon"
                            style={{height: "32px", paddingRight: "8px",}}/>
                        A Message</Nav.Link>*/}
                    {/*<NavDropdown title="My Games" className="mainNavbarElement" id="navbarScrollingDropdown">*/}
                    {/*    <NavDropdown.Item href="/mygames/adriftprogram">Adrift Program</NavDropdown.Item>*/}
                    {/*    <NavDropdown.Item href="/mygames/_space">_ Space</NavDropdown.Item>*/}
                    {/*    <NavDropdown.Divider />*/}
                    {/*    <NavDropdown.Item href="/mygames">*/}
                    {/*        All Games*/}
                    {/*    </NavDropdown.Item>*/}
                    {/*</NavDropdown>*/}
                    <Nav.Link href="#mailinglist" className="mainNavbarElement"
                              // tabIndex={3}
                              style={{height: "inherit",
                        display: "flex", alignItems: "center",}}>
                        <img src="/UI%20Graphics/game-icons.net/envelope.png"
                             alt="Envelope Icon"
                             style={{height: "24px", paddingRight: "8px",}}/>
                        Mailing List</Nav.Link>
                    {/*<Nav.Link to="/games/insomniac_moles#start" as={Link} className="mainNavbarElement"*/}
                    <Nav.Link href="/games/insomniac_moles#start" className="mainNavbarElement"
                              // tabIndex={3}
                              style={{height: "inherit",
                        display: "flex", alignItems: "center",}}>
                        <img src="/Images/Game%20Icons/TEMP Insomniac Moles Icon White 24x24 SCALED.png"
                             alt="Insomniac Moles Icon"
                             style={{height: "24px", paddingRight: "8px",}}/>
                        Play Insomniac Moles</Nav.Link>
                </Container>
            </Navbar>
        </Container>
    );
}

export default MainNavbar;