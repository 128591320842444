// Abstraction of the data fetching of news posts
// .

import {types} from "sass";
import Error = types.Error;
import {NewsPostData, NewsPostHeaderData} from "../Data Types/NewsPostData";
//import * as All from "/public/Exposed News Datas/CoalescedNewsPosts";
//import * as All from "/src/Data Types/GameListNodes/Style Goblin.json";


const fetchLocalNewsPosts = async (): Promise<NewsPostData[]> => {
    const fetchUrl: string = '/Static Test Datas/coalesced_newsposts_public.json';
    //const fetchUrl: string = '/src/Data Types/coalesced_newsposts.json';
    //const fetchUrl: string = '/src/Data Types/GameListNodes/Style Goblin.json';
    //console.log("Fetching news posts...")
    const response = await fetch(fetchUrl);
    if (!response.ok) {
        throw new Error('Failed to fetch local news posts');
    }
    //console.log("Fetched news posts!")
    return await response.json();
};

const fetchApiNewsPosts = async (): Promise<NewsPostData[]> => {
    const fetchUrl: string = 'https://www.blawnode.com/api/newsposts';
    const response = await fetch(fetchUrl);
    if (!response.ok) {
        throw new Error('Failed to fetch news posts from API');
    }
    return await response.json();
};

// Use this function to determine which method to call
export const fetchNewsPosts = async (useApi: boolean): Promise<NewsPostData[]> => {
    const rawNewsPosts: NewsPostData[] = await (useApi ? fetchApiNewsPosts() : fetchLocalNewsPosts());

    const dateifiedNewsPosts: NewsPostData[] = rawNewsPosts.map((post: any) => ({
        ...post,
        releaseDate: new Date(post.releaseDate) // Convert string to Date
    }));

    return dateifiedNewsPosts;
};



const fetchLocalNewsPostsHeaders = async (): Promise<NewsPostHeaderData[]> => {
    const fetchUrl: string = '/Static Test Datas/coalesced_newsheaders_public.json';
    //console.log("Fetching news post HEADERS...")
    const response = await fetch(fetchUrl);
    if (!response.ok) {
        throw new Error('Failed to fetch local news posts HEADERS');
    }
    //console.log("Fetched news post HEADERS!")
    return await response.json();
};

const fetchApiNewsPostsHeaders = async (): Promise<NewsPostHeaderData[]> => {
    const fetchUrl: string = 'https://www.blawnode.com/api/newsheaders';  // A cut version of `/newsposts`.
    const response = await fetch(fetchUrl);
    if (!response.ok) {
        throw new Error('Failed to fetch news posts HEADERS from API');
    }
    return await response.json();
};

// Use this function to determine which method to call
export const fetchNewsPostsHeaders = async (useApi: boolean): Promise<NewsPostHeaderData[]> => {
    return useApi ? fetchApiNewsPostsHeaders() : fetchLocalNewsPostsHeaders();
};