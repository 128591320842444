import React, {useState} from "react";
import IsMobile from "../IsMobile";
import {NewsPostData} from "../Data Types/NewsPostData";
import {fetchPost, setSelectedPost, setSelectedPostId} from "../Redux Store/actions";
import store, {AppDispatch} from "../Redux Store/store";
import {useDispatch, useSelector} from 'react-redux';
import {useApi} from "../Constants";
import {stringifyDate} from "../Helper Scripts/DateHelper";
import {RootState} from "../Redux Store/reducers";

// NewsPostData.tsx is a generic news data, both for the small list panels, and the full large view.
// Out of it, some of the data will be here in these props:
type NewsPanelElementProps = {
    newsId: string;
    title: string;
    date: string;
    panelClickUrl?: string;  // The primary associated media.

    thumbnailUrl?: string;
    isPixelated?: boolean;
}
const NewsPanelElement = ({newsId, title, date, panelClickUrl, thumbnailUrl, isPixelated = false}: NewsPanelElementProps) => {
    const dispatch: AppDispatch = useDispatch();
    const isSelected = useSelector((state: RootState) => state.selectedPost)?.id == newsId;
    //const isSelected = useSelector() == newsId;
    //     const selectedPost = useSelector((state: RootState) => state.selectedPost); // Accessing the data from Redux store


    const hasPanelClickUrl = panelClickUrl != null;

    // Overrides <a/> appearance. Cursor stays the same though, because clicking on a news panel causes a news post to be displayed.
    const unclickableStyle: any = {
            //pointerEvents: ("none style" as React.CSSProperties["pointerEvents"]),
            cursor: "pointer",
            textDecoration: "none",
            color: "#fff",
        };
    const renderThumbnailAsPixelArt: any = isPixelated ?
        { imageRendering: "pixelated",}
        :
        {};
    const selectedStyle: any = isSelected ?
        { backgroundColor: "#a167b7",}
        :
        {};

    function OnClick(/*eventvar*/){
        //newsID
        //setSelectedPostId(newsId);
        console.log(`setSelectedPostId(${newsId});`);
        //dispatchMethod = fetchPost(newsId);  // Currently, this method does setSelectedPost().
        //store.dispatch({type: "SET_SELECTED_POST_ID", payload: newsId});
        //dispatch({type: "SET_SELECTED_POST_ID", payload: newsId});//
        dispatch(setSelectedPostId(newsId));
        dispatch(fetchPost(newsId, useApi));
        //store.dispatch({type: "SET_SELECTED_POST", payload: {what: "what"}});
        /*let newsPost = *///fetchPost(newsId);
        //setSelectedPost(newsPost);
    }

    // TODO: Have a tiny overlay icon button: <a href={panelClickUrl}/>
    return <a onClick={OnClick}
       className="news-panel"
       style={{
           position: "relative", marginBottom: "8px",
           width: IsMobile() ? "80%" : "100%", height: IsMobile() ? "6vh" : "14vh",
           display: "flex", flexDirection: "row", justifyContent: "space-between",
           ...unclickableStyle, ...selectedStyle,
       }}>
        <div style={{
            margin: 0, padding: IsMobile() ? 0 : "8px",
            minWidth: 0,
            textAlign: "left",
        }}>
            <p style={{
                fontSize: "1em",
                marginBottom: "0.2rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
            }}>{title}</p>

            {!IsMobile() && <p style={{
                fontSize: "0.9em", color: "#9badb7",
                marginBottom: "0.2rem",
            }}>{date}</p>}
        </div>
        <img style={{
            borderRadius: "8%",
            aspectRatio: "16/9",
            ...renderThumbnailAsPixelArt
        }}
             src={thumbnailUrl == null ? "/Images/YouTube Video Thumbnails/Music/Default Thumbnail.jpg" : thumbnailUrl}/>

        {panelClickUrl &&
        <div style={{position: "absolute", top: "2px", right: "2px", width: "20px", height: "20px",
            backgroundColor: "#00000080",
            borderRadius: "8px",
            display: "flex", alignItems: "center", justifyContent: "center",}}>
            <a href={panelClickUrl} target="_blank">
                {/*https://icons.getbootstrap.com/icons/link/*/}
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="#d77bba"
                     className="bi bi-link" viewBox="0 0 16 16">
                    <path
                        d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                    <path
                        d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
                </svg>
            </a>
        </div>}
    </a>
}


type NewsPanelElementScrollerProps = {
    newsElements: React.JSX.Element[];  // ASSUMPTION: These are of the type <NewsPanelElement/>.
}
const NewsPanelElementScroller = ({newsElements,}: NewsPanelElementScrollerProps) => {
    const [newsIndex, setNewsIndex] = useState(0);

    return <div style={{display: "flex", justifyContent: "row", alignItems: "center",}}>
        <a style={{width: "10%",}} onClick={() => setNewsIndex((newsIndex - 1 + newsElements.length) % newsElements.length)}>&lt;</a>
        {newsPanelElements[newsIndex]}
        <a style={{width: "10%",}} onClick={() => setNewsIndex((newsIndex + 1) % newsElements.length)}>&gt;</a>
    </div>;
}


const NewsPanelHeaderStyle_NonMobile: any = {marginBottom: "16px", marginLeft: "4px", marginRight: "4px",};
const NewsPanelHeaderStyle_Mobile: any = {marginBottom: "16px", marginLeft: "4px", marginRight: "4px",};
const NewsPanelHeaderStyle: any = IsMobile() ? NewsPanelHeaderStyle_Mobile : NewsPanelHeaderStyle_NonMobile;

const NewsPanelStyle_NonMobile: any = {
    width: "100%", height: "60%",
    fontSize: "0.75rem",
};
const NewsPanelStyle_Mobile: any = {
    width: "90%", height: "100%",
    padding: "8px",
    fontSize: "0.75rem",
    display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center",
    backgroundColor: "rgb(118, 66, 138)",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
};
const NewsPanelStyle: any = IsMobile() ? NewsPanelStyle_Mobile : NewsPanelStyle_NonMobile;

// TODO OPTIONAL: Put these in a folder?
//  Get all .jsons in that folder, and make elements outta theme?
//  Or perhaps in the perfect situation, get em from a file server.

// TODO: Find a solution to the displaying of several posts that are scrollable. A Bootstrap carousel?
const newsPosts: NewsPostData[] = [
    {
        id: "NEWS-INWEB_GAME-INSOMNIAC_MOLES",
        title: "Play Now! - Insomniac Moles",
        tags: ["blawnode_com"],
        releaseDate: new Date("2024-11-11"),
        panelClickUrl: "/games/insomniac_moles",  // Inweb game url! Like, href="/games/insomniac_moles"
        thumbnailUri: "/Images/Game Thumbnails/Insomniac Moles News Thumbnail.png",
        isPixelated: true,
    },
    {
        id: "NEWS-WEBSITE_MAINTANENCE2",
        title: "Website maintenance - Redux Implemented!",
        tags: ["blawnode_com"],
        releaseDate: new Date("2024-10-21"),
        thumbnailUri: "/Images/News Exclusive Thumbnails/bn_com maintenance news thumbnail 2.png",
        isPixelated: true,
    },
    {
        id: "NEWS-WEBSITE_MAINTANENCE1",
        title: "New ongoing website maintenance!",
        tags: ["blawnode_com"],
        releaseDate: new Date("2024-09-06"),
        thumbnailUri: "/Images/News Exclusive Thumbnails/bn_com maintenance news thumbnail 1.png",
        isPixelated: true,
    },
    {
        id: "NEWS-NUISANCE_RELEASE",
        title: "Nuisance is released!",
        tags: ["game, nuisance"],
        releaseDate: new Date("2024-09-04"),
        panelClickUrl: "https://blawnode.itch.io/nuisance",
        thumbnailUri: "/Images/Game Thumbnails/Nuisance Thumbnail 16x9.png",
        isPixelated: true,
    },
    {
        id: "NEWS-REMIX_LA_PLAISANTERIE_SAVANTE",
        title: "EBF5 Remix: La Plaisanterie Savante!",
        tags: ["remix", "chiptune", "epicbattlefantasy5"],
        releaseDate: new Date("2024-06-05"),
        panelClickUrl: "https://www.youtube.com/watch?v=ZGJgIYUw-tY",
        thumbnailUri: "/Images/YouTube Video Thumbnails/Music/Music_LaPlaisanterieSavante.jpg",
        isPixelated: true,
    },
    {
        id: "NEWS-WEBSITE_NEWS_INITIALIZATION",
        title: "Begun construction of News Panel!",
        tags: ["blawnode_com"],
        releaseDate: new Date("2024-05-13"),
        thumbnailUri: "/Images/YouTube Video Thumbnails/Music/Default Thumbnail.jpg",
        isPixelated: true,
    },
];

const newsPanelElements: React.JSX.Element[] = [
    ...(newsPosts.map(newsPost => (
        <NewsPanelElement key={newsPost.id}
                          newsId={newsPost.id}
                          title={newsPost.title}
                          date={stringifyDate(newsPost.releaseDate).replaceAll(".", '/')}
                          panelClickUrl={newsPost.panelClickUrl}
                          thumbnailUrl={newsPost.thumbnailUri}
                          isPixelated={newsPost.isPixelated}/>))),

    /*<NewsPanelElement title="News panel was created!" date="13/05/2024"/>,*/
];

const NewsPanel = () => {
    const NewsPanelHeader = () =>
        <div style={NewsPanelHeaderStyle}>
            <h3>
                <img src="/UI%20Graphics/game-icons.net/newspaper.png"
                     alt="Newspaper Icon"
                     style={{height: "40px", paddingRight: "12px",}}/>
                <span>News</span>
                <img src="/UI%20Graphics/game-icons.net/newspaper.png"
                     alt="Newspaper Icon"
                     style={{height: "40px", paddingLeft: "12px",}}/>
            </h3>
        </div>;

    return <div id="news" style={{...NewsPanelStyle,}}>
        <div id="news-background" style={{width: "90%", height: "100%", margin: "auto",
            backgroundColor: "rgb(118, 66, 138)",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",}}>
        </div>
        <div id="news-foreground" style={{position: "relative", top: "-100%",
            height: "100%",
            padding: "8px",}}>
            <div style={{height: "10%",}}><NewsPanelHeader/></div>

            <div style={{height: "5%",}}/>

            <div id="news-expandable-container" style={{width: "120%", height: "90%",
            position: "relative", left: "-10%",
            overflowY: "scroll", scrollbarGutter: "stable both-edges", scrollbarWidth: "none",
            padding: 0,}}>
            <div id="news-resizer" style={{width: "83.34%", height: "100%", margin: "auto",
                padding: "8px",}}>
                <div style={{fontSize: "calc(3px + 1vmin)", width: "100%",}}>
                    {IsMobile() ? <NewsPanelElementScroller newsElements={newsPanelElements}/> : newsPanelElements}
                </div>
            </div>
        </div>
        </div>
    </div>;
}

export default NewsPanel;