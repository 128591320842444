import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';
import {useEffect, useState} from "react";
import MailchimpFormContainer from "./MailchimpFormContainer";
import MainNavbar from "./MainNavbar";
import IsMobile from "./IsMobile";
import BlankSpaceSegment from "./FullPageSegments/BlankSpaceSegment";
import WipSegment from "./FullPageSegments/WipSegment";
import {fetchNewsPosts, fetchNewsPostsHeaders} from "./Services/NewsPostDataService";
import {NewsPostData, NewsPostHeaderData} from "./Data Types/NewsPostData";
import {useApi} from "./Constants";
import {IsInDevelopment} from "./Helper Scripts/IsInDevelopment";
import {Unity, useUnityContext, UnityConfig} from "react-unity-webgl";
import {BrowserRouter, createBrowserRouter, RouterProvider, Route, Routes, Navigate} from "react-router-dom";
import {UnityProvider} from "react-unity-webgl/distribution/types/unity-provider";
import {ReactUnityEventParameter} from "react-unity-webgl/distribution/types/react-unity-event-parameters";


enum gameIds{
    INSOMNIAC_MOLES = "GAME-INSOMNIAC_MOLES",
}

interface GameSegmentProps{
    gameId: string;
}

// TODO: Extend this.
type LoadableGameMetadata = {
    gameName: string;
    gameFilePath: string;
}

const GameSegment = ({gameId}: GameSegmentProps) => {
    let [currUnityProvider, currIsLoaded, currLoadingProgression, currSendMessage, currAddEventListener, currRemoveEventListener, currInitialisationError]:
        [null | UnityProvider,
            boolean,
            number,
                null | ((gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void),
                null | ((eventName: string, callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter) => void),
                null | ((eventName: string, callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter) => void),
                null | Error,
        ]
        = [
        null, false, 0, null, null, null, null,
    ];

    /*// TODO
    const GetUnityGameFilePathGeneric: (targetGameId: string) => string | null
        = (targetGameId: string) => {
        let resultGameFilePath: string | null = null;

        if(targetGameId == gameIds.INSOMNIAC_MOLES)
        {
            resultGameFilePath = "/Game Datas/Insomniac Moles/Build/Build WebGL";
        }
        else{
            console.error(`The game ${targetGameId} is currently not supported! Finish programming this.`);
        }

        return resultGameFilePath;
    }

    const GetUnityGameFilePath: () => string | null
        = () => {
        return GetUnityGameFilePathGeneric(gameId);
    }*/

    // TODO
    const GetUnityGameMetadataGeneric: (targetGameId: string) => LoadableGameMetadata | null
        = (targetGameId: string) => {
        let resultGameData: LoadableGameMetadata | null = null;

        if (targetGameId == gameIds.INSOMNIAC_MOLES) {
            resultGameData = {
                gameName: "Insomniac Moles",
                //gameFilePath: "/Game Datas/Insomniac Moles/Build/Build WebGL",
                gameFilePath: "https://blawnode-game-build-files.s3.us-east-1.amazonaws.com/Build/Insomniac+Moles",
            };
        } else {
            console.error(`The game ${targetGameId} is currently not supported! Finish programming this.`);
        }

        return resultGameData;
    }

    const GetUnityGameMetadata: () => LoadableGameMetadata | null
        = () => GetUnityGameMetadataGeneric(gameId);

    function LoadUnityGameContext(gameFilePath: string) {
        const {unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, removeEventListener, initialisationError,} = useUnityContext({
            loaderUrl: gameFilePath + ".loader.js",
            dataUrl: gameFilePath + ".data",
            frameworkUrl: gameFilePath + ".framework.js",
            codeUrl: gameFilePath + ".wasm",
        } as UnityConfig);

        [currUnityProvider, currIsLoaded, currLoadingProgression, currSendMessage, currAddEventListener, currRemoveEventListener, currInitialisationError,]
            = [unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, removeEventListener, initialisationError];
    }


    let gameMetadata: LoadableGameMetadata | null = GetUnityGameMetadata();
    if (gameMetadata) LoadUnityGameContext(gameMetadata.gameFilePath);

    currSendMessage = (gameObjectName: string, methodName: string) => {console.log("1110");};
    currAddEventListener = (gameObjectName: string, callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter) => {console.log("2220");};
    currRemoveEventListener = (gameObjectName: string, callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter) => {console.log("3330");};

    return <div>
        {currUnityProvider && <div style={{paddingTop: "10vh",}}>
            <Unity unityProvider={currUnityProvider}
                   style={{width: "50vh", height: "50vh", aspectRatio: "auto 1 / 1",}}/>
        </div>}
        {(currUnityProvider && Math.round(currLoadingProgression * 100) == 100) &&
            <div style={{width: `${Math.round(currLoadingProgression * 100)}%`,
                height: "4px",
            backgroundColor: "#f448",}}/>}
        {(!currUnityProvider && Math.round(currLoadingProgression * 100) == 100) &&
            <div style={{width: `${Math.round(currLoadingProgression * 100)}%`,
                height: "4px",
                backgroundColor: "#4f48",}}/>}
        {(Math.round(currLoadingProgression * 100) == 100) &&
            <div style={{width: `${Math.round(currLoadingProgression * 100)}%`,
                height: "4px",
                backgroundColor: "#44f8",}}/>}
        {!currUnityProvider && <h2>WARNING: The game {gameId} could not be loaded.</h2>}
        {currInitialisationError !== null && <h2>WARNING: The detected an initialization error for the game {gameId}: {currInitialisationError}</h2>}

        <br/>

        {currUnityProvider &&
            <div>
                {/*TODO: (Full game HTML metadata and description goes here.)*/}
                <h2>{gameMetadata?.gameName}</h2>
                Developers: Blawnode, redsti
            </div>
        }
    </div>;
};

const PageNotFoundSegment = () => {
    return <div>
        <h1 style={{color: "#d95763"}}>HEY. GO BACK.</h1>
        <p>... This page does not exist.</p>
    </div>;
}


const BodySegment = () => {
    return <div className="App-body" id="start">
            <Routes>
                <Route path="/" element={<WipSegment/>} />
                <Route path="/ping" element={<div>Pong!</div>} />
                <Route path="/games/insomniac_moles" element={<GameSegment gameId={gameIds.INSOMNIAC_MOLES}/>} />
                <Route path="*" element={<PageNotFoundSegment/>} />
            </Routes>
    </div>;
};


const MailingListFooterSegment = () => {
    return <footer id="mailinglist">
        <MailchimpFormContainer/>
    </footer>;
};


const isObject = (val: any) => typeof val === 'object' && val !== null;

const attemptToStringifyJson = (value: any) => isObject(value) ? JSON.stringify(value) : value;


function App() {
    const [serviceNews, setServiceNews] = useState<NewsPostData[] | null>(null);
    const [serviceNewsHeaders, setServiceNewsHeaders] = useState<NewsPostHeaderData[] | null>(null);
    //const [serviceNewsStringified, setServiceNewsStringified] = useState("unfetched");
    const insomniacMolesDirectory = "/Game Datas/Insomniac Moles/Build/";  // TODO: Move these to some external file of constants, like an .env file. Or whatever fits.
    const insomniacMolesFileName = "Build WebGL";  // TODO: Validate that this path exists for all 4 files
    const { unityProvider, sendMessage, addEventListener, removeEventListener,  } = useUnityContext({
        loaderUrl: insomniacMolesDirectory + insomniacMolesFileName + ".loader.js",
        dataUrl: insomniacMolesDirectory + insomniacMolesFileName + ".data",
        frameworkUrl: insomniacMolesDirectory + insomniacMolesFileName + ".framework.js",
        codeUrl: insomniacMolesDirectory + insomniacMolesFileName + ".wasm",
    } as UnityConfig);

    useEffect(() => {
        async function fetchNewsViaService(){
            const fetchedTestNews = await fetchNewsPosts(useApi);
            setServiceNews(fetchedTestNews);

            //const fetchedTestNewsStringified = attemptToStringifyJson(fetchedTestNews);
            //setServiceNewsStringified(fetchedTestNewsStringified);
        }
        async function fetchNewsHeadersViaService(){
            const fetchedTestNewsHeaders = await fetchNewsPostsHeaders(useApi);
            setServiceNewsHeaders(fetchedTestNewsHeaders);

            //const fetchedTestNewsStringified = attemptToStringifyJson(fetchedTestNews);
            //setServiceNewsStringified(fetchedTestNewsStringified);
        }

        fetchNewsHeadersViaService()
            .catch(console.error);
        fetchNewsViaService()
            .catch(console.error);
    }, []);

    function fetchNewsPostData(newsId: string): NewsPostData | null {
        return fetchNewsPostRelatedData<NewsPostData>(newsId, serviceNews);
    }
    function fetchNewsPostHeaderData(newsId: string): NewsPostHeaderData | null {
        return fetchNewsPostRelatedData<NewsPostHeaderData>(newsId, serviceNewsHeaders);
    }

    interface objectWithId{
        id: string,
    }

    function fetchNewsPostRelatedData<T extends objectWithId>(newsId: string, preFetchedData: T[] | null /*fetchMethod = (useApi: boolean) => T*/): T | null{
        if(preFetchedData === null)
        {
            return null;
        }

        let foundNewsPostRelatedData = (preFetchedData as T[]).find(
            newsPostRelatedData => newsPostRelatedData.id == newsId);  // Returns either a NewsPostData, or undefined.
        let returnedNewsPostRelatedData = foundNewsPostRelatedData === undefined ?
            null : foundNewsPostRelatedData;
        return returnedNewsPostRelatedData;
    }

    return (
        <BrowserRouter>
        <div className="App">
            {!IsMobile() && <MainNavbar/>}

            <BodySegment/>

            {IsInDevelopment() &&
            // If in development build, then show this:
            <>
                <div style={{overflowWrap: "break-word",}}>
                    {(serviceNews != null) &&
                    <p>{attemptToStringifyJson(fetchNewsPostHeaderData("NEWS-TEST_MAXIMAL"))}</p>}
                    {(serviceNewsHeaders != null) &&
                    <p>{attemptToStringifyJson(fetchNewsPostData("NEWS-TEST_MINIMAL"))}</p>}
                </div>
            </>
            }

            <div style={{
                width: "100%", height: "20vh",
                backgroundImage: "linear-gradient(#45283c, #222034)",
            }}/>

            <MailingListFooterSegment/>
        </div>
            </BrowserRouter>
    );
}

export default App;
