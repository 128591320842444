// WebStorm suggests to swap out createStore for configureStore.

import { createStore, applyMiddleware, Store } from 'redux';
import { thunk, ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import rootReducer, { RootState, /*ActionTypes,*/ } from './reducers';
import {ActionTypes} from "./actions";

const store: Store<RootState> = createStore(rootReducer, applyMiddleware(thunk));

//export type RootState = ReturnType<typeof store.getState>;  // Already defined properly in /reducers/.

export type AppDispatch = ThunkDispatch<RootState, unknown, ActionTypes>;  // TypeScript + Thunk -> Typed Dispatch
//export type AppDispatch = ThunkMiddleware<RootState, ActionTypes>;  // TypeScript + Thunk -> Typed Dispatch
//export type AppDispatch = typeof store.dispatch;  // TypeScript + Thunk -> Typed Dispatch
export default store;